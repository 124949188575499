 <template>
  <section class="innerpage-wrap">
    <div class="container" ref="saveEmployer">
      <div class="split-title">
        <h1 class="brand-color">Employer Information</h1>
      </div>
      <div class="enrollmentForm card-block">
        <form class="" action="index.html" method="post">
          <div class="form-group row">
            <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-12  mb-4" v-if="formFields.name.required == true">
              <label>Employer Information <span class="required">*</span></label>
              <input type="text"
                     class="custom-input"
                     @input="$v.formFields.name.value.$touch(); removeError();"
                     :class="{'form-control is-invalid':$v.formFields.name.value.$error || formErrors['name_message']}"
                     placeholder="Enter Employer Information"
                     name=""
                     id=""
                     v-model="formFields.name.value">
              <div class="form-group__message text-left ml-3 mt-2"  v-if="!$v.formFields.name.value.required && $v.formFields.name.value.$error">
                Field is required
              </div>
              <div class="form-group__message  ml-3 mt-2" v-if="$v.formFields.name.value.required && $v.formFields.name.value.$error">
                Invalid employer information
              </div>
              <div class="error_message" v-if="formErrorFlag == true">{{formErrors['name_message']}}</div>
            </div>

            <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-12  mb-4" v-if="formFields.phone.required == true">
              <label>Contact Number <span class="required">*</span></label>
              <div class="d-flex align-items-center">
                <input type="text"
                       class="custom-input"
                       placeholder="Enter Contact Number"
                       maxlength="10"
                       @input="$v.formFields.phone.value.$touch(); removeError();"
                       :class="{'form-control is-invalid':$v.formFields.phone.value.$error || formErrors['phone_message'] || isPhoneInvalid}"
                       name=""
                       id=""
                       v-model="formFields.phone.value" >
                <div  @click="renderVerificationModal('phoneNumber')" v-if="isPhoneInvalid">
                  <img :src="verificationPhoneEmail" alt="img" class="verification-icon" v-b-tooltip.hover title="Invalid phone number ! Please click to validate your number"/>
                </div>
                <div class="custom-check-wrap" v-if="isPhoneVerificationSuccess">
                  <i class="fa fa-check custom-check"></i>
                </div>
              </div>
              <div class="form-group__message text-left ml-3 mt-2"  v-if="!$v.formFields.phone.value.required && $v.formFields.phone.value.$error">
                Field is required
              </div>
              <div class="form-group__message text-left ml-3 mt-2" v-if="$v.formFields.phone.value.required && $v.formFields.phone.value.$invalid">
                Invalid phone number
              </div>
              <div class="error_message" v-if="formErrorFlag == true">
                {{formErrors['phone_message']}}
                <span  v-if="isPhoneInvalid" >Please <span  @click="renderVerificationModal('phoneNumber')" class="verification-error-text"> click here </span> to validate via OTP.
                  </span>
              </div>
            </div>

            <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-12  mb-4" v-if="formFields.address1.required == true">
              <label>Street Address 1 <span class="required">*</span></label>
              <input type="text"
                     class="custom-input"
                     placeholder="Enter Street Address"
                     @input="$v.formFields.address1.value.$touch(); removeError();"
                     :class="{'form-control is-invalid':$v.formFields.address1.value.$error || formErrors['address1_message']}"
                     name=""
                     id=""
                     v-model="formFields.address1.value">
              <div class="form-group__message text-left ml-3 mt-2"  v-if="!$v.formFields.address1.value.required && $v.formFields.address1.value.$error">
                Field is required
              </div>
              <div class="error_message" v-if="formErrorFlag == true">{{formErrors['address1_message']}}</div>
            </div>

            <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-12  mb-4" v-if="formFields.address2.required == true">
              <label>Street Address 2 <span class="required">*</span></label>
              <input type="text"
                     class="custom-input"
                     placeholder="Enter Street Address"
                     name="" id=""
                     @input="$v.formFields.address2.value.$touch(); removeError();"
                     :class="{'form-control is-invalid':$v.formFields.address2.value.$error || formErrors['address2_message']}"
                     v-model="formFields.address2.value">
              <div class="form-group__message text-left ml-3 mt-2"  v-if="!$v.formFields.address2.value.required && $v.formFields.address2.value.$error">
                Field is required
              </div>
              <div class="error_message" v-if="formErrorFlag == true">{{formErrors['address2_message']}}</div>
            </div>
          </div>

          <div class="form-group row">
            <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-12  mb-4" v-if="formFields.city.required == true">
              <label>City <span class="required">*</span></label>
              <input type="text" class="custom-input"
                     placeholder="Enter City"
                     name=""
                     id=""
                     @input="$v.formFields.city.value.$touch(); removeError();"
                     :class="{'form-control is-invalid':$v.formFields.city.value.$error || formErrors['city_message']}"
                     v-model="formFields.city.value" value="">
              <div class="form-group__message text-left ml-3 mt-2"  v-if="!$v.formFields.city.value.required && $v.formFields.city.value.$error">
                Field is required
              </div>
              <div class="error_message" v-if="formErrorFlag == true">{{formErrors['city_message']}}</div>
            </div>

            <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-12  mb-4" v-if="formFields.city.required == true">
              <label>State <span class="required">*</span></label>
              <input type="text"
                     class="custom-input"
                     placeholder="Enter State"
                     name=""
                     id=""
                     @input="$v.formFields.state.value.$touch(); removeError();"
                     :class="{'form-control is-invalid':$v.formFields.state.value.$error || formErrors['state_message']}"
                     v-model="formFields.state.value" v-on:keyup="state_case()" value="">
              <div class="form-group__message text-left ml-3 mt-2"  v-if="!$v.formFields.state.value.required && $v.formFields.state.value.$error">
                Field is required
              </div>
              <div class="error_message" v-if="formErrorFlag == true">{{formErrors['state_message']}}</div>
            </div>

            <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-12  mb-4" v-if="formFields.zip.required == true">
              <label>Zip <span class="required">*</span></label>
              <input type="text"
                     class="custom-input"
                     placeholder="Enter Zip Code"
                     name="" maxlength="5" id=""
                     @input="$v.formFields.zip.value.$touch(); removeError();"
                     :class="{'form-control is-invalid':$v.formFields.zip.value.$error || formErrors['zip_message']}"
                     v-model="formFields.zip.value" value=""
                     oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);" >
              <div class="form-group__message text-left ml-3 mt-2"  v-if="!$v.formFields.zip.value.required && $v.formFields.zip.value.$error">
                Field is required
              </div>
              <div class="form-group__message text-left ml-3 mt-2" v-if="$v.formFields.zip.value.required && $v.formFields.zip.value.$invalid">
                Invalid zip code
              </div>
              <div class="error_message" v-if="formErrorFlag == true">{{formErrors['zip_message']}}</div>
            </div>
          </div>

          <div class="form-group" v-if="!(checkExtraHealthRedirection())">
            <div class="form-check form-check-inline" v-if="formFields.with_validation.required == true">
              <input class="form-check-input" type="checkbox" name="" id="inlinecheckbox1" value="option1" v-model="formFields.with_validation.value">
              <label class="form-check-label" for="inlinecheckbox1">USPS Address Validation</label>
            </div>
          </div>

          <div class="form-group row">
            <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-12  mb-4" v-if="formFields.average_work_week.required == true">
              <label>Average Work Week <span class="required">*</span></label>
              <b-form-select v-model="formFields.average_work_week.value"
                             class="custom-input"
                             @input="$v.formFields.average_work_week.value.$touch(); removeError();"
                             :class="{'form-control is-invalid':$v.formFields.average_work_week.value.$error || formErrors['average_work_week_message']}"
                             id="tier"
                             :options="avgWorkWeek">
              </b-form-select>
              <div class="form-group__message text-left ml-3 mt-2"  v-if="!$v.formFields.average_work_week.value.required && $v.formFields.average_work_week.value.$error">
                Field is required
              </div>
              <div class="error_message" v-if="formErrorFlag == true">{{formErrors['average_work_week_message']}}</div>
            </div>
            <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-12  mb-4" v-if="formFields.employment_start_date.required == true">
              <label>Employment Start Date <span class="required">*</span></label>
              <v-date-picker
                  v-model="formFields.employment_start_date.value"
                  @input="$v.formFields.employment_start_date.value.$touch(); removeError(); convertIntoDateFormat();"
                  :popover="{ visibility: 'click' }"
                  mode="date">
                <template v-slot="{ inputValue, inputEvents }">
                  <input
                      class="custom-input"
                      placeholder="mm/dd/yyyy"
                      :class="{'invalid':$v.formFields.employment_start_date.value.$error || formErrors['employment_start_date_message']}"
                      size=10 maxlength=10
                      :value="inputValue.replace(/^(\d\d)(\d)$/g,'$1/$2').replace(/^(\d\d\/\d\d)(\d+)$/g,'$1/$2').replace(/[^\d\/]/g,'')"
                      v-on="inputEvents" />
                </template>
              </v-date-picker>
              <div class="form-group__message text-left ml-3 mt-2"  v-if="!$v.formFields.employment_start_date.value.required && $v.formFields.employment_start_date.value.$error">
                Field is required
              </div>
              <div class="error_message" v-if="formErrorFlag == true">{{formErrors['employment_start_date_message']}}</div>
              <div class="form-group__message text-left ml-3 mt-2" v-if="$v.formFields.employment_start_date.value.required &&
              $v.formFields.employment_start_date.value.$invalid
              ">
                Start date should not be more than 2 months from effective date
              </div>
              <div class="error_message" v-if="formErrorFlag == true">
                {{ formErrors['employment_start_date_message'] }}
              </div>
            </div>
            <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-12  mb-4" v-if="formFields.occupation.required == true">
              <label>Occupation <span class="required">*</span></label>
              <input type="text"
                     class="custom-input"
                     placeholder="Enter Occupation"
                     @input="$v.formFields.occupation.value.$touch(); removeError();"
                     :class="{'form-control is-invalid':$v.formFields.occupation.value.$error || formErrors['occupation_message']}"
                     name=""
                     id=""
                     v-model="formFields.occupation.value" value="">
              <div class="form-group__message text-left ml-3 mt-2"  v-if="!$v.formFields.occupation.value.required && $v.formFields.occupation.value.$error">
                Field is required
              </div>
              <div class="error_message" v-if="formErrorFlag == true">{{formErrors['occupation_message']}}</div>
            </div>
          </div>

          <div class="form-group row">
            <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-12  mb-4" v-if="formFields.compensation_type.required == true">
              <label>Compensation Type <span class="required">*</span></label>
              <b-form-select v-model="formFields.compensation_type.value"
                             class="custom-input"
                             @input="$v.formFields.compensation_type.value.$touch(); removeError();"
                             :class="{'form-control is-invalid':$v.formFields.compensation_type.value.$error || formErrors['compensation_type_message']}"
                             id="compensation_type"
                             :options="compType">
              </b-form-select>
              <div class="form-group__message text-left ml-3 mt-2"  v-if="!$v.formFields.compensation_type.value.required && $v.formFields.compensation_type.value.$error">
                Field is required
              </div>
              <div class="error_message" v-if="formErrorFlag == true">{{formErrors['compensation_type_message']}}</div>
            </div>
            <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-12  mb-4" v-if="formFields.employment_status.required == true">
              <label>Employment Status <span class="required">*</span></label>
              <b-form-select v-model="formFields.employment_status.value"
                             class="custom-input"
                             @input="$v.formFields.employment_status.value.$touch(); removeError(); removeEmploymentStatusError();"
                             :class="{'form-control is-invalid':$v.formFields.employment_status.value.$error || formErrors['employment_status_message']
                                    || employmentStatusErrorMessage}"
                             id="employment_status"
                             :options="empStatus">
              </b-form-select>
              <div class="form-group__message text-left ml-3 mt-2"  v-if="!$v.formFields.employment_status.value.required && $v.formFields.employment_status.value.$error">
                Field is required
              </div>
              <div class="error_message mt-2" v-if="employmentStatusErrorMessage">
                {{employmentStatusErrorMessage}}
              </div>
              <div class="error_message" v-if="formErrorFlag == true">{{formErrors['employment_status_message']}}</div>
            </div>
            <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-12  mb-4" v-if="formFields.emp_industry.required == true">
              <label>Employer Industry<span class="required">*</span></label>
              <input type="text"
                     class="custom-input"
                     @input="$v.formFields.emp_industry.value.$touch(); removeError();"
                     :class="{'form-control is-invalid':$v.formFields.emp_industry.value.$error || formErrors['emp_industry_message']}"
                     placeholder="Enter employer industry"
                     name=""
                     id=""
                     v-model="formFields.emp_industry.value"
                     value="">
              <div class="form-group__message text-left ml-3 mt-2"  v-if="!$v.formFields.emp_industry.value.required && $v.formFields.emp_industry.value.$error">
                Field is required
              </div>
              <div class="error_message" v-if="formErrorFlag == true">{{formErrors['emp_industry_message']}}</div>
            </div>

          </div>
          <div class="form-group row">
            <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-12  mb-4" v-if="formFields.emp_num_employed.required == true">
              <label>Number of Employees (count youself as 1)<span class="required">*</span></label>
              <input type="number"
                     class="custom-input"
                     placeholder="Enter number of employees"
                     name=""
                     @input="$v.formFields.emp_num_employed.value.$touch(); removeError();"
                     :class="{'form-control is-invalid':$v.formFields.emp_num_employed.value.$error || formErrors['emp_num_employed_message']}"
                     id=""
                     v-model="formFields.emp_num_employed.value"
                     value="">
              <div class="form-group__message text-left ml-3 mt-2"  v-if="!$v.formFields.emp_num_employed.value.required && $v.formFields.emp_num_employed.value.$error">
                Field is required
              </div>
              <div class="error_message" v-if="formErrorFlag == true">{{formErrors['emp_num_employed_message']}}</div>
            </div>
            <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-12  mb-4" v-if="formFields.emp_vac_policy.required == true">
              <label>Vacation Policy <span class="required">*</span></label>
              <b-form-select name="" class="custom-input" id="average_work_week"  @input="$v.formFields.emp_vac_policy.value.$touch(); removeError();"
                      :class="{'form-control is-invalid':$v.formFields.emp_vac_policy.value.$error || formErrors['emp_vac_policy_message']}" v-model="formFields.emp_vac_policy.value">
                <option value="null" sselected disabled>Select Vacation Policy</option>
                <option v-for="item in vacPolicy" :value="item.value" v-bind:key="item.value">{{item.name}}</option>
              </b-form-select>
              <div class="form-group__message text-left ml-3 mt-2"  v-if="!$v.formFields.emp_vac_policy.value.required && $v.formFields.emp_vac_policy.value.$error">
                Field is required
              </div>
              <div class="error_message" v-if="formErrorFlag == true">{{formErrors['emp_vac_policy_message']}}</div>
            </div>
            <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-12  mb-4" v-if="formFields.emp_barg_unit.required == true">
              <label>Bargaining Unit <span class="required">*</span></label>
              <b-form-select name="" class="custom-input" id="average_work_week"  @input="$v.formFields.emp_barg_unit.value.$touch(); removeError();"
                      :class="{'form-control is-invalid':$v.formFields.emp_barg_unit.value.$error || formErrors['emp_barg_unit_message']}" v-model="formFields.emp_barg_unit.value">
                <option value="null" selected disabled>Select Bargaining Unit </option>
                <option v-for="item in BargainUnit" :value="item.value" v-bind:key="item.value">{{item.name}}</option>
              </b-form-select>
              <div class="form-group__message text-left ml-3 mt-2"  v-if="!$v.formFields.emp_barg_unit.value.required && $v.formFields.emp_barg_unit.value.$error">
                Field is required
              </div>
              <div class="error_message" v-if="formErrorFlag == true">{{formErrors['emp_barg_unit_message']}}</div>
            </div>
          </div>
          <div class="form-group row">
            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12  mb-12" v-if="formFields.holiday_policy.required == true">
              <label>Holiday Policy <span class="required">*</span></label><br>
              <ul style="display:flex; list-style:none;padding-left: 0rem;">
                <li v-for="item in HolyPol" v-bind:key="item.name">
                  <input style="margin-right:0.3rem;"
                         :class="selectHolidayPolicy ? 'form-check-input is-invalid': 'form-check-input'"
                         type="checkbox" name=""
                         :id="item.name"
                         @click="check($event,item.name)"
                         value="1"
                         v-model="item.value">
                  <label style="margin-right:1.2rem;" class="form-check-label" > {{item.name}}</label>
                </li>
              </ul>
              <div class="form-group__message text-left ml-3"  style="position: relative; top: -6px;" v-if="selectHolidayPolicy">
                Select at least one holiday policy
              </div>
              <div class="error_message" v-if="formErrorFlag == true">{{formErrors['holiday_policy_message']}}</div>
            </div>
          </div>
        </form>

      </div>
      <div class="action-btn split-title mt-5">
        <router-link to="" @click.native="navigatePrevious" class="dark-btn">Back</router-link>
        <div class="action-next">
          <router-link to="" @click.native="saveEnrollment" class="button-green" v-if="!(checkExtraHealthRedirection())">Save & Continue Later</router-link>
          <router-link to="" @click.native="skipEmployeeDataForIHA" class="button-brand skip-btn" v-if="isIHAPlan">Skip</router-link>
          <router-link to="" @click.native="saveData" class="button-brand">Next</router-link>
        </div>
      </div>

    </div>

    <b-modal v-bind:hide-footer="true" id="code-send" centered size="md" >
      <template #modal-header>
        <div class="popUp-header" style="border-bottom: 0px !important">
          <div  v-if="saveSuccess">
            <h4 style="color: green"><b>Information Saved!!!</b> Check your email to continue</h4>
          </div>
          <div v-else>
            <h4>Please enter the alternate email you want to send Enrollment code to?</h4>
          </div>
          <b-button variant="close-btn" @click="$bvModal.hide('code-send')">
            <i class="fas fa-times"></i>
          </b-button>
        </div>
      </template>
      <b-container fluid>
        <div v-if="saveSuccess==false" class="input_box client_email">
          <input type="text"
                 class="custom-input"
                 :class="{'form-control is-invalid': email && !isEmailValid || isEmailNull}"
                 @input="emailValid(); removeError();"
                 v-model="email"
                 value="" />
          <div class="form-group__message  ml-3 mt-2" v-if="email && !isEmailValid || isEmailNull">
            Enter valid email address
          </div>
          <div class="error_message" v-if="formErrorFlag == true">{{formErrors['email_message']}}</div>
        </div>
        <div class="popUp-details text-center">
          <div v-if="saveSuccess" class="action-btn text-center mt-4">
            <b-button style="width:100%;"  class="button-brand mx-1" @click="$bvModal.hide('code-send')" data-dismiss="modal">Done</b-button>
          </div>
          <div v-else class="action-btn text-center mt-4">
            <b-button style="width:40%;"  class="dark-btn mx-1" @click="$bvModal.hide('code-send')">Back</b-button>
            <b-button style="width:40%;"  class="button-brand mx-1" @click="saveAndContinueEnrollment" data-dismiss="modal">Send Code</b-button>
          </div>
        </div>
      </b-container>

    </b-modal>
    <div>
      <email-phone-verification :verificationType="verificationType"
                                :verificationObj="verificationObj" @verificationStatus="renderVerificationStatus"/>
    </div>
  </section>
</template>

<script>
import axios from 'axios';
import html2canvas from 'html2canvas';
import {required} from "vuelidate/lib/validators";
import {
  checkEmail,
  checkEmployerInformation,
  checkValidPhoneNumber,
  checkValidZip,
  checkValidDate
} from "../../utils/validators";
import {checkExtraHealthGroup} from "../../utils/checkPlatform";
import EmailPhoneVerification from "../../views/EmailPhoneVerification";
export default {
  name: "EmployerInformation",
  data: function () {
    return {
      tempId: '',
      // formFields: [],
      formFields: {
        name: {
          value: '',
          required: false,
          read_only: false,
        },
        phone: {
          value: '',
          required: false,
          read_only: false,
        },
        address1: {
          value: '',
          required: false,
          read_only: false,
        },
        address2: {
          value: '',
          required: false,
          read_only: false,
        },
        city: {
          value: 0,
          required: false,
          read_only: false,
        },
        state: {
          value: 0,
          required: false,
          read_only: false,
        },
        zip: {
          value: 0,
          required: false,
          read_only: false,
        },
        average_work_week: {
          value: true,
          required: false,
          read_only: false,
        },
        employment_start_date: {
          value: true,
          required: false,
          read_only: false,
        },
        occupation: {
          value: true,
          required: false,
          read_only: false,
        },
        compensation_type: {
          value: true,
          required: false,
          read_only: false,
        },
        employment_status: {
          value: true,
          required: false,
          read_only: false,
        },
        with_validation: {
          value: true,
          required: false,
          read_only: false,
        },
        emp_industry: {
          value: true,
          required: false,
          read_only: false,
        },
        emp_num_employed: {
          value: true,
          required: false,
          read_only: false,
        },
        emp_vac_policy: {
          value: true,
          required: false,
          read_only: false,
        },
        emp_barg_unit: {
          value: true,
          required: false,
          read_only: false,
        },
        holiday_policy: {
          value: [],
          required: false,
          read_only: false,
        }
      },
      avgWorkWeek: [],
      empStatus: [],
      compType: [],
      formErrors: [],
      formErrorFlag: false,
      savePopup: false,
      email: '',
      saveSuccess: false,
      isEmailNull: false,
      isEmailValid: false,
      vacPolicy:[],
      BargainUnit:[],
      HolyPol:[],
      checkbox_val : [],
      selectHolidayPolicy: false,
      employmentStatusErrorMessage: '',
      isIHAPlan: false,
      effective_date: '',
      isPhoneInvalid: false,
      isPhoneVerificationSuccess: false,
      verificationType: '',
      verificationObj: {},
      verificationPhoneEmail: 'https://enrollmentlogo.s3.us-east-2.amazonaws.com/warning-sign.png'
    }
  },
  computed: {
    landingInfo () {
      return this.$store.getters.getLandingInfo
    }
  },
  components: {EmailPhoneVerification},
  mounted() {
    let app = this;
    app.tempId = window.localStorage.getItem('tempId');
    app.scrollTop();
    app.getFields();
    app.getAvgWeek();
    app.getEmpStatus();
    app.getCompType();
    app.getVacPol();
    app.getBargainingUnit();
    app.getHolidayPolicy();
    app.checkForIHAPlanOnCart();
    app.getEffDate();
  },
  validations() {
    if (this.formFields.emp_industry.required && this.formFields.emp_num_employed.required && this.formFields.emp_vac_policy.required && this.formFields.emp_barg_unit.required) {
      return {
        formFields: {
          name: {
            value: {
              required,
              checkEmployerInformation
            }
          },
          phone: {
            value: {
              required,
              checkValidPhoneNumber
            }
          },
          city: {
            value: {
              required
            }
          },
          zip: {
            value: {
              required,
              checkValidZip
            }
          },
          address1: {
            value: {
              required
            }
          },
          state: {
            value: {
              required
            }
          },
          compensation_type: {
            value: {
              required
            }
          },
          occupation: {
            value: {
              required
            }
          },
          employment_start_date: {
            value: {
              required,
              checkValidDate
            }
          },
          employment_status: {
            value: {
              required
            }
          },
          average_work_week: {
            value: {
              required
            }
          },
          emp_industry: {
            value: {
              required
            }
          },
          emp_num_employed: {
            value: {
              required
            }
          },
          emp_vac_policy: {
            value: {
              required
            }
          },
          emp_barg_unit: {
            value: {
              required
            }
          }
        }
      }
    } else if (this.formFields.address2.required) {
      return {
        formFields: {
          name: {
            value: {
              required,
              checkEmployerInformation
            }
          },
          phone: {
            value: {
              required,
              checkValidPhoneNumber
            }
          },
          zip: {
            value: {
              required,
              checkValidZip
            }
          },
          city: {
            value: {
              required
            }
          },
          address1: {
            value: {
              required
            }
          },
          address2: {
            value: {
              required
            }
          },
          state: {
            value: {
              required
            }
          },
          compensation_type: {
            value: {
              required
            }
          },
          occupation: {
            value: {
              required
            }
          },
          employment_start_date: {
            value: {
              required,
              checkValidDate
            }
          },
          employment_status: {
            value: {
              required
            }
          },
          average_work_week: {
            value: {
              required
            }
          }
        }
      }
    } else {
      return {
        formFields: {
          name: {
            value: {
              required,
              checkEmployerInformation
            }
          },
          phone: {
            value: {
              required,
              checkValidPhoneNumber
            }
          },
          city: {
            value: {
              required
            }
          },
          zip: {
            value: {
              required,
              checkValidZip
            }
          },
          address1: {
            value: {
              required
            }
          },
          state: {
            value: {
              required
            }
          },
          compensation_type: {
            value: {
              required
            }
          },
          occupation: {
            value: {
              required
            }
          },
          employment_start_date: {
            value: {
              required,
              checkValidDate
            }
          },
          employment_status: {
            value: {
              required
            }
          },
          average_work_week: {
            value: {
              required
            }
          }
        }
      }
    }
  },
  methods: {
    checkValue: function(str, max) {
      if (str.charAt(0) !== '0' || str == '00') {
        var num = parseInt(str);
        if (isNaN(num) || num <= 0 || num > max) num = 1;
        str = num > parseInt(max.toString().charAt(0)) && num.toString().length == 1 ? '0' + num : num.toString();
      }
      return str;
    },
    eventDob: function() {
      let app = this;
      var input = app.formFields.employment_start_date.value;
      if (/\D\/$/.test(input)) input = input.substr(0, input.length - 1);
      var values = input.split('/').map(function(v) {
        return v.replace(/\D/g, '')
      });
      if (values[0]) values[0] = app.checkValue(values[0], 12);
      if (values[1]) values[1] = app.checkValue(values[1], 31);
      var output = values.map(function(v, i) {
        return v.length == 2 && i < 2 ? v + '/' : v;
      });
      app.formFields.employment_start_date.value = output.join('').substr(0, 10);
    },
    getFields: function () {
      let app = this;
      axios.get(process.env.VUE_APP_API_BASE+'/get-form-fields-employer-info', {
        params: {
          enrollment_id: app.tempId,
        }
      })
          .then(function (response) {
            if (response.data.status == 'success') {
              response.data.data.result.forEach(function(item) {
                app.formFields[item.field_name] = item;
                if (item.field_name === 'employment_start_date') {
                  app.formFields.employment_start_date.value = app.checkdateFormat(item.value)
                }
              });
            }
            app.fetchInfo();
          })
          .catch(function (error) {
            console.log(error);
          });
    },
    check: function(e,name) {
      let app = this;
      if (e.target.checked) {
        app.checkbox_val.push({'name':name,'value':e.target.value});
      }
      else{
        app.checkbox_val=app.checkbox_val.filter(function(el) {
          return el.name != name;
        })
      }
      if (this.formFields.holiday_policy.required) {
        this.selectHolidayPolicy = true;
        if (this.checkbox_val.length > 0) {
          this.selectHolidayPolicy = false;
        }
      }
    },
    state_case: function(){
      let app = this;
      var state=app.formFields.state.value;
      if(state.length <= 2){
        state=state.toUpperCase();
      }
      else{
        state=state.toLowerCase();
        state = state.replace(/^(.)|\s+(.)/g, c => c.toUpperCase());
      }
      app.formFields.state.value=state;
    },
    fetchInfo: function () {
      let app = this;
      app.checkbox_val=[];
      let collector = [];
      axios.get(process.env.VUE_APP_API_BASE+'/get-enrollment-temp-info', {
        params: {
          enrollment_id: app.tempId,
          column: 'employer_info',
        }
      })
          .then(function (response) {
            if (response.data.status == 'success') {
              if (!response.data.data.result || response.data.data.result === null) {
                app.getEmployerDefaultInformation();
              } else {
                console.log('This is the fetched data' + JSON.stringify(response.data.data.result));
                app.formFields.address1.value = response.data.data.result.address1;
                app.formFields.employment_start_date.value = response.data.data.result.employment_start_date;
                app.formFields.average_work_week.value = response.data.data.result.average_work_week;
                app.formFields.compensation_type.value = response.data.data.result.compensation_type;
                app.formFields.employment_status.value = response.data.data.result.employment_status;
                app.formFields.occupation.value = response.data.data.result.occupation;
                app.formFields.city.value = response.data.data.result.city;
                app.formFields.state.value = response.data.data.result.state;
                app.formFields.zip.value = response.data.data.result.zip;
                app.formFields.phone.value = response.data.data.result.phone;
                app.formFields.name.value = response.data.data.result.name;
                app.formFields.with_validation.value = response.data.data.result.with_validation;
                app.formFields.emp_industry.value = response.data.data.result.emp_industry;
                app.formFields.emp_num_employed.value = response.data.data.result.emp_num_employed;
                app.formFields.emp_vac_policy.value = response.data.data.result.emp_vac_policy;
                app.formFields.emp_barg_unit.value = response.data.data.result.emp_barg_unit;
                collector = response.data.data.result.holiday_policy;
                Object.keys(collector).forEach(function (key) {
                  if (key == 'Standard_6') {
                    app.checkbox_val.push({'name': 'Standard 6', 'value': collector[key]});
                  }
                  if (key == 'Presendents_Day') {
                    app.checkbox_val.push({'name': 'Presendents Day', 'value': collector[key]});
                  }
                  if (key == 'Columbus_Day') {
                    app.checkbox_val.push({'name': 'Columbus Day', 'value': collector[key]});
                  }
                  if (key == 'Religious_Days') {
                    app.checkbox_val.push({'name': 'Religious Days', 'value': collector[key]});
                  }
                  if (key == 'Birthday') {
                    app.checkbox_val.push({'name': 'Birthday', 'value': collector[key]});
                  }
                  if (key == 'Floating_Day') {
                    app.checkbox_val.push({'name': 'Floating Day', 'value': collector[key]});
                  }
                });
                let map = new Map(app.checkbox_val.map(o => [o.name, o]));
                app.HolyPol.forEach(o => map.has(o.name) && (o.value = map.get(o.name).value));
                // for(var i in response.data.data.result) {
                //     fetchedData.push([i, response.data.data.result [i]]);
                // }
                // fetchedData.forEach(function(item) {
                //     let key = item[0];
                //     let val = item[1];
                //     app.formFields[key]['value'] = val;
                // });
              }
            }
          })
          .catch(function (error) {
            console.log(error);
          });
    },
    getEmployerDefaultInformation () {
      let app = this;
      axios.get(process.env.VUE_APP_API_BASE+'/get-group-info', {
        params: {
          enrollment_id: app.tempId
        }
      })
          .then(function (response) {
            if (response.data.status == 'success') {
              app.formFields.name.value = response.data.data.general_info.group_name;
              app.formFields.phone.value = response.data.data.general_info.group_phone;
              app.formFields.address1.value = response.data.data.address_information.address1;
              app.formFields.city.value = response.data.data.address_information.city;
              app.formFields.state.value = response.data.data.address_information.state;
              app.formFields.zip.value = response.data.data.address_information.zip;
            }
          })
          .catch(function (error) {
            console.log(error);
          });
    },
    checkdateFormat (str) {
      let regexp = /[-]+/g
      if (regexp.test(str)) {
        let datePart = str.match(/\d+/g),
        year = datePart[0].substring(0),
        month = datePart[1],
        day = datePart[2];
        return `${month}/${day}/${year}`
      } else {
        return str
      }
    },
    getAvgWeek: function () {
      let app = this;
      axios.get(process.env.VUE_APP_API_BASE+'/get-average-work-week')
          .then(function (response) {
            if (response.data.status == 'success') {
              let avgWorkWeekList = [
                {value: null, text: 'Average Work Week', disable:true}
              ];
              response.data.data.result.forEach( list => {
                let avgWorkWeek = {
                  value: list.value,
                  text: list.name
                }
                avgWorkWeekList.push(avgWorkWeek);
              })
              app.avgWorkWeek = avgWorkWeekList;
            }
          })
          .catch(function (error) {
            console.log(error);
          });
    },
    getVacPol: function () {
      let app = this;
      axios.get(process.env.VUE_APP_API_BASE+'/get-vacation-policy',{
        params: {
          enrollment_id: app.tempId,
        }
      })
          .then(function (response) {
            if (response.data.status == 'success') {
              app.vacPolicy = response.data.data.result;
            }
          })
          .catch(function (error) {
            console.log(error);
          });
    },
    getBargainingUnit: function () {
      let app = this;
      axios.get(process.env.VUE_APP_API_BASE+'/get-bargaining-unit',{
        params: {
          enrollment_id: app.tempId,
        }
      })
          .then(function (response) {
            if (response.data.status == 'success') {
              app.BargainUnit = response.data.data.result;
            }
          })
          .catch(function (error) {
            console.log(error);
          });
    },
    getHolidayPolicy: function(){
      let app = this;
      axios.get(process.env.VUE_APP_API_BASE+'/get-holiday-policy',{
        params: {
          enrollment_id: app.tempId,
        }
      })
          .then(function (response) {
            if (response.data.status == 'success') {
              app.HolyPol = response.data.data.result;
            }
          })
          .catch(function (error) {
            console.log(error);
          });
    },
    getEmpStatus: function () {
      let app = this;
      axios.get(process.env.VUE_APP_API_BASE+'/get-employment-status')
          .then(function (response) {
            if (response.data.status == 'success') {
              let empStatusList = [
                {value: null, text: 'Select Employment Status', disable:true}
              ];
              response.data.data.result.forEach( list => {
                let empStatus = {
                  value: list.value,
                  text: list.name
                }
                empStatusList.push(empStatus);
              })
              app.empStatus = empStatusList;
            }
          })
          .catch(function (error) {
            console.log(error);
          });
    },
    getCompType: function () {
      let app = this
      axios.get(process.env.VUE_APP_API_BASE+'/get-compensation-types', {
        params: {
          enrollment_id: app.tempId,
        }
      })
          .then(function (response) {
            if (response.data.status == 'success') {
              let compTypeList = [
                {value: null, text: 'Compensation Type', disable:true}
              ];
              response.data.data.result.forEach( list => {
                let compType = {
                  value: list.value,
                  text: list.name
                }
                compTypeList.push(compType);
              })
              app.compType = compTypeList;
            }
          })
          .catch(function (error) {
            console.log(error);
          });
    },
    saveData: function () {
      this.$v.$touch();
      if (this.formFields.holiday_policy.required && this.checkbox_val.length === 0) {
        console.log('***** inside holiday policy validation ******')
        this.selectHolidayPolicy = true;
         if (this.checkbox_val.length > 0) {
             this.selectHolidayPolicy = false;
         }
      } else {
        if (this.$v.$invalid || this.employmentStatusErrorMessage) {
          return false
        } else {
          const el = this.$refs.saveEmployer;
          let collector = {};
          (async () => {
            this.saveScreenShot((await html2canvas(el)).toDataURL('image/png'), 'employer_info');
            this.screenShotData = (await html2canvas(el)).toDataURL();
          })();
          // Submit API
          let app = this;
          app.formErrorFlag = false;
          let map = new Map(app.checkbox_val.map(o => [o.name, o]));
          app.HolyPol.forEach(o => map.has(o.name) && (o.value = map.get(o.name).value));
          app.HolyPol.forEach(item => {
            if (item.name == 'Standard 6') {
              collector['Standard_6'] = Number(item.value);
            }
            if (item.name == 'Presendents Day') {
              collector['Presendents_Day'] = Number(item.value);
            }
            if (item.name == 'Columbus Day') {
              collector['Columbus_Day'] = Number(item.value);
            }
            if (item.name == 'Religious Days') {
              collector['Religious_Days'] = Number(item.value);
            }
            if (item.name == 'Birthday') {
              collector['Birthday'] = Number(item.value);
            }
            if (item.name == 'Floating Day') {
              collector['Floating_Day'] = Number(item.value);
            }
          });
          app.formErrors = [];
          var with_validation = 0;
          if (app.formFields.with_validation.value == true) {
            with_validation = 1;
          } else {
            with_validation = 0;
          }
          let params = {
            enrollment_id: app.tempId,
            name: app.formFields.name.value,
            phone: app.formFields.phone.value,
            address1: app.formFields.address1.value,
            address2: app.formFields.address2.value,
            city: app.formFields.city.value,
            state: app.formFields.state.value,
            zip: app.formFields.zip.value,
            average_work_week: app.formFields.average_work_week.value,
            employment_start_date: app.formFields.employment_start_date.value,
            occupation: app.formFields.occupation.value,
            compensation_type: app.formFields.compensation_type.value,
            employment_status: app.formFields.employment_status.value,
            with_validation: with_validation,
            emp_industry: app.formFields.emp_industry.value,
            emp_num_employed: app.formFields.emp_num_employed.value,
            emp_vac_policy: app.formFields.emp_vac_policy.value,
            emp_barg_unit: app.formFields.emp_barg_unit.value,
            holiday_policy: collector,
          };
          //discard null values
          for (const key of Object.keys(params)) {
            if (params[key] === null) {
              delete params[key];
            }
          }
          console.log(params);
          axios.post(process.env.VUE_APP_API_BASE + '/save-employer-info', params)
              .then(function (response) {
                if (response.data.status == 'success') {
                  console.log("success");
                  app.saveAndContinue();
                }
              })
              .catch(function (error) {
                if (error.response.data.status == 'error') {
                  error.response.data.data.forEach(function (item) {
                    app.formErrorFlag = true;
                    app.formErrors[item.target_element + '_message'] = item.error_message;
                    console.log(app.formErrors[item.target_element + '_message']);
                    (item.target_element === 'phone_number') && (app.isPhoneInvalid = !item.is_valid);
                  });
                }
              });
        }
      }
    },
    skipEmployeeDataForIHA: function () {
      const el = this.$refs.saveEmployer;
      let collector = {};
      (async () => {
        this.saveScreenShot((await html2canvas(el)).toDataURL('image/png'), 'employer_info');
        this.screenShotData = (await html2canvas(el)).toDataURL();
      })();
      // Submit API
      let app = this;
      app.formErrorFlag = false;
      let map = new Map(app.checkbox_val.map(o => [o.name, o]));
      app.HolyPol.forEach(o => map.has(o.name) && (o.value = map.get(o.name).value));
      app.HolyPol.forEach(item => {
        if (item.name == 'Standard 6') {
          collector['Standard_6'] = Number(item.value);
        }
        if (item.name == 'Presendents Day') {
          collector['Presendents_Day'] = Number(item.value);
        }
        if (item.name == 'Columbus Day') {
          collector['Columbus_Day'] = Number(item.value);
        }
        if (item.name == 'Religious Days') {
          collector['Religious_Days'] = Number(item.value);
        }
        if (item.name == 'Birthday') {
          collector['Birthday'] = Number(item.value);
        }
        if (item.name == 'Floating Day') {
          collector['Floating_Day'] = Number(item.value);
        }
      });
      app.formErrors = [];
      var with_validation = 0;
      if (app.formFields.with_validation.value == true) {
        with_validation = 1;
      } else {
        with_validation = 0;
      }
      let params = {
        enrollment_id: app.tempId,
        name: '',
        phone: '',
        address1: '',
        address2: '',
        city: '',
        state: '',
        zip: '',
        average_work_week: '',
        employment_start_date: '',
        occupation: '',
        compensation_type: '',
        employment_status: '',
        with_validation: with_validation,
        emp_industry: '',
        emp_num_employed: '',
        emp_vac_policy: '',
        emp_barg_unit: '',
        holiday_policy: collector
      };
      //discard null values
      for (const key of Object.keys(params)) {
        if (params[key] === null) {
          delete params[key];
        }
      }
      console.log(params);
      axios.post(process.env.VUE_APP_API_BASE + '/save-employer-info', params)
          .then(function (response) {
            if (response.data.status == 'success') {
              console.log("success");
              app.saveAndContinue();
            }
          })
          .catch(function (error) {
            if (error.response.data.status == 'error') {
              error.response.data.data.forEach(function (item) {
                app.formErrorFlag = true;
                app.formErrors[item.target_element + '_message'] = item.error_message;
                console.log(app.formErrors[item.target_element + '_message']);
              });
            }
          });
    },
    saveAndContinue() {
      //Update APIs
      //Navigate to next page
      if (localStorage.getItem("navto") === null || localStorage.getItem("navto") === undefined || localStorage.getItem("navto") === '') {
        this.$emit('comEnvNavigation', this.$route.name);
      }else{
        window.localStorage.removeItem('navto');
        this.$router.push('review-confirm');
      }
    },
    saveAndContinueEnrollment: function () {
      if (!this.isEmailValid) {
        this.isEmailNull = true;
      } else {
        let app = this;
        let collector = {};
        let map = new Map(app.checkbox_val.map(o => [o.name, o]));
        app.HolyPol.forEach(o => map.has(o.name) && (o.value = map.get(o.name).value));
        app.HolyPol.forEach(item => {
          if(item.name == 'Standard 6'){
            collector['Standard_6']= Number(item.value);
          }
          if(item.name == 'Presendents Day'){
            collector['Presendents_Day']=Number(item.value);
          }
          if(item.name == 'Columbus Day'){
            collector['Columbus_Day']=Number(item.value);
          }
          if(item.name == 'Religious Days'){
            collector['Religious_Days']=Number(item.value);
          }
          if(item.name == 'Birthday'){
            collector['Birthday']=Number(item.value);
          }
          if(item.name == 'Floating Day'){
            collector['Floating_Day']=Number(item.value);
          }
        });
        let params = {
          name:                  app.formFields.name.value,
          phone:                 app.formFields.phone.value,
          address1:              app.formFields.address1.value,
          address2:              app.formFields.address2.value,
          city:                  app.formFields.city.value,
          state:                 app.formFields.state.value,
          zip:                   app.formFields.zip.value,
          average_work_week:     app.formFields.average_work_week.value,
          employment_start_date: app.formFields.employment_start_date.value,
          occupation:            app.formFields.occupation.value,
          compensation_type:     app.formFields.compensation_type.value,
          employment_status:     app.formFields.employment_status.value,
          emp_industry:          app.formFields.emp_industry.value,
          emp_num_employed:      app.formFields.emp_num_employed.value,
          emp_vac_policy:        app.formFields.emp_vac_policy.value,
          emp_barg_unit:         app.formFields.emp_barg_unit.value,
          holiday_policy:        collector,
        };
        for (const key of Object.keys(params)) {
          if (params[key] === null) {
            delete params[key];
          }
        }
          let data = {
            enrollment_id: app.tempId,
            email: app.email,
            tab: 'employer_info',
            data: params
          }
          app.formErrorFlag = false;
          app.formErrors = [];
          axios.post(process.env.VUE_APP_API_BASE + '/save-enrollment', data)
              .then(function (response) {
                if (response.data.status == 'success') {
                  app.saveSuccess = true;
                  console.log("success");
                }
              })
              .catch(function (error) {
                if (error.response.data.status == 'error') {
                  console.log('error');
                  error.response.data.data.forEach(function (item) {
                    app.formErrorFlag = true;
                    app.formErrors[item.target_element + '_message'] = item.error_message;
                  });
                  console.log(app.formErrors);
                }
              });
       }
    },
    saveEnrollment : function () {
      let app = this;
      app.$bvModal.show('code-send');
    },
    closePopup : function () {
      let app = this;
      app.savePopup = false;
    },
    navigatePrevious() {
      //Update APIs
      //Navigate to next page
      this.$emit('comEnvNavigationBack', this.$route.name);
    },
    saveScreenShot (image, name) {
      let app = this;
      let data = {
        "enrollment_id" : app.tempId,
        "name" : name,
        "image" : image
      };
      let config = {
        method: 'post',
        url: process.env.VUE_APP_API_BASE+'/save-screenshot',
        headers: { },
        data : data
      };
      axios(config)
          .then(function (response) {
            if (response.data.status == 'success') {
              console.log('screenshot saved');
            }
          })
          .catch(function (error) {
            console.log('screenshot not saved');
            console.log(error);
          });
    },
    scrollTop(){
      window.scrollTo(0,0);
    },
    removeError () {
      this.formErrors = [],
      this.isPhoneInvalid = false
    },
    removeEmploymentStatusError () {
      this.employmentStatusErrorMessage = ''
    },
    emailValid () {
      this.isEmailValid = checkEmail(this.email)
      this.isEmailNull = !checkEmail(this.email)
    },
    convertIntoDateFormat () {
      let app = this
      let date = app.formFields.employment_start_date.value
      let dd = date.getDate()
      let mm = date.getMonth() + 1
      let yyyy = date.getFullYear()
      if (dd < 10) {
        dd = '0' + dd
      }
      if (mm < 10) {
        mm = '0' + mm
      }
      app. formFields.employment_start_date.value = mm + '/' + dd + '/' + yyyy;
    },
    checkForIHAPlanOnCart () {
      let app = this
      axios.get(process.env.VUE_APP_API_BASE+'/get-cart-plan-info', {
        params: {
          enrollment_id: app.tempId,
        }
      })
          .then(function (response) {
            console.log(response)
            app.isIHAPlan = response.data.data.is_IHA
          })
          .catch(function (error) {
            console.log(error)
          });
    },
    getEffDate: function () {
      let app = this;
      axios
          .get(process.env.VUE_APP_API_BASE + "/get-enrollment-temp-info", {
            params: {
              enrollment_id: app.tempId,
              column: 'effective_date',
            },
          })
          .then(function (response) {
            if (response.data.status == "success") {
              app.effective_date = ''
              app.effective_date = response.data.data.result
              window.localStorage.setItem('effectiveDate', app.effective_date)
            }
          })
          .catch(function (error) {
            console.log(error);
          });
    },
    checkExtraHealthRedirection () {
      let app = this
      return (checkExtraHealthGroup(app.landingInfo))
    },
    renderVerificationModal (type) {
      let app = this
      app.$bvModal.show('verifyModal')
      app.verificationType = type
      app.verificationObj['phoneNumber'] =  app.formFields.phone.value
    },
    renderVerificationStatus (params) {
      let app = this
      if (params.type === 'phone') {
        app.isPhoneVerificationSuccess = true
        app.isPhoneInvalid = false
        app.formFields.phone.value = params.contact
      }
      app.removeError();
    },
  },
  watch: {
    'formFields.employment_status.value': function () {
      let app = this
      if (app.formFields.employment_status.value === 'R') {
        console.log('show that selected value is R')
        app.employmentStatusErrorMessage = 'You cannot proceed with retired employer'
      }
    }
  }
}
</script>

<style scoped>
.skip-btn {
  margin-right: 10px;
}

.custom-check-wrap{
  background-color:#11809c ;
  border-radius: 50px;
  max-width: fit-content;
  max-height: fit-content;
  padding: 0.2rem 0.6rem;
  margin-left: 0.1rem 0.5rem;
  position: relative;
  left: 8px;
}
.custom-check-wrap svg{
  filter: brightness(0) invert(1);
  border-radius: 0.8rem;
  font-size: 10px;
}
.red-icon {
  color: red;
}
.ver-icon-align {
  margin-left: 5px;
}
.verification-icon {
  width: 35px;
  margin: 2px 4px;
  cursor: pointer;
}
.verification-error-text {
  text-decoration: underline;
  cursor: pointer;
  color: #11809c;
}
</style>
