<template>
  <b-modal id="extra-health-verification"
           hide-header-close
           @shown="renderModal"
           size="lg" >
      <template #modal-header>
        <div class="popUp-header" style="border-bottom: 0px !important">
          <b-button variant="close-btn" @click="$bvModal.hide('extra-health-verification')">
            <i class="fas fa-times"></i>
          </b-button>
        </div>
      </template>
      <div class="verification-container"  ref="extraHealthVerification">
        <h2 class="text-uppercase">Client Signature</h2>
        <div class="mt-3">
          <label class="text-uppercase">Send text verification</label>
          <div class="form-group row">
            <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12  mb-4">
              <input type="number" class="custom-input"
                     id="phoneNumber"
                     placeholder="Enter phone number"
                     @input="$v.sendPhoneNumber.$touch(); removeError()"
                     :class="{'form-control is-invalid':$v.sendPhoneNumber.$error || errorPhoneMsg}"
                     v-model="sendPhoneNumber"
                     value=""
                     maxlength="10" ref="phone" >
              <div class="form-group__message text-left ml-3 mt-2"  v-if="!$v.sendPhoneNumber.required && $v.sendPhoneNumber.$error">
                Field is required
              </div>
              <div class="form-group__message text-left ml-3 mt-2" v-if="$v.sendPhoneNumber.required && $v.sendPhoneNumber.$invalid">
                Invalid phone number
              </div>
              <div class="error_message" v-if="errorPhoneMsg">{{errorPhoneMsg}}</div>
            </div>
            <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-6">
              <b-button  class="text-uppercase send-verification-button" @click="checkPhoneNumberVerification">Send</b-button>
              <span v-if="showPhoneValidTick"> <i class="fa fa-check-circle fa-2x email-phone-alert-success mr-2"></i></span>
            </div>
          </div>
        </div>
        <div>
          <label class="text-uppercase">Send Email verification</label>
          <div class="form-group row">
            <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12  mb-4">
              <input type="text" class="custom-input"
                     id="email"
                     placeholder="Enter email address"
                     @input="$v.sendEmail.$touch(); removeError()"
                     :class="{'form-control is-invalid':$v.sendEmail.$error || errorEmailMsg}"
                     v-model="sendEmail"
                     value="" ref="email">
              <div class="form-group__message text-left ml-3 mt-2"  v-if="!$v.sendEmail.required && $v.sendEmail.$error">
                Field is required
              </div>
              <div class="form-group__message text-left ml-3 mt-2" v-if="$v.sendEmail.required && $v.sendEmail.$invalid">
                Enter Valid Email
              </div>
              <div class="error_message" v-if="errorEmailMsg">{{errorEmailMsg}}</div>
            </div>
            <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-12">
              <b-button  class="text-uppercase send-verification-button" @click="checkEmailVerification">Send</b-button>
              <span v-if="showEmailValidTick"><i class="fa fa-check-circle fa-2x email-phone-alert-success mr-2"></i></span>
            </div>
          </div>
        </div>
        <div class="form-group row mt-2 mb-2">
          <div class="col-xxl-8 col-xl-8 col-lg-8 col-md-6 col-12  mb-4">
            <b-button  class="text-uppercase check-verification-button" @click="checkSignatureValidation">
              <span class="verify-text">Check Verification Status</span>
              <img src="../../../assets/images/refresh.svg" class="rotational-spinner" v-if="renderSpinnerIcon"/>
              <img src="../../../assets/images/refresh.svg" v-else />
            </b-button>
          </div>
        </div>
        <div v-if="showVerificationMsg" class="mb-4">
          <div class="verification-alert-success" v-if="hasSignatureSubmitted && paymentStatus">
            <span>Signature received and payment is successful. You can now complete the enrollment.</span>
          </div>
          <div class="verification-alert-danger" v-else-if="!hasSignatureSubmitted">
            Signature has not been added, please follow the link sent to your phone or email to complete the enrollment.
          </div>
        </div>
      </div>
      <div v-if="showPaymentError" class="mb-4">
        <div class="verification-alert-danger">
          {{paymentErrorMsg}}
          <u style="
                color: #0094d9;
                font-weight: 600;
                cursor: pointer;
            " @click="routeToCC">Click here to change payment detail</u>
        </div>
      </div>
      <div class="form-group row">
        <div class="col-xxl-8 col-xl-8 col-lg-8 col-md-6 col-12  mb-4">
          <b-button  class="text-uppercase send-verification-button" :disabled="!(hasSignatureSubmitted && paymentStatus)" @click="submitData">Complete Enrollment</b-button>
        </div>
      </div>
  </b-modal>
</template>

<script>
import {email, required} from "vuelidate/lib/validators";
import {checkValidPhoneNumber} from "../../../utils/validators";
import axios from "axios";
import html2canvas from "html2canvas";
export default {
  name: "ExtraHealthVerificationModal",
  data () {
    return {
      sendEmail: '',
      sendPhoneNumber: '',
      tempId: '',
      errorPhoneMsg: '',
      errorEmailMsg: '',
      showEmailValidTick: false,
      showPhoneValidTick: false,
      showVerificationMsg: false,
      hasSignatureSubmitted: false,
      renderSpinnerIcon: false,
      showPaymentError: false,
      paymentErrorMsg: '',
      paymentStatus: false
    }
  },
  props: {
    contactInfo: {
      type: Object
    }
  },
  validations: {
    sendEmail: {
      required,
      email
    },
    sendPhoneNumber: {
      required,
      checkValidPhoneNumber
    }
  },
  mounted() {
    let app = this
    app.tempId = window.localStorage.getItem('tempId');
  },
  methods: {
    checkEmailVerification () {
      let app = this
      app.showVerificationMsg = false
      app.$v.sendEmail.$touch();
      if (app.$v.sendEmail.$invalid) {
        return
      } else {
        app.sendVerificationLink (app.sendEmail, 'email');
      }
    },
    checkPhoneNumberVerification () {
      let app = this
      app.showVerificationMsg = false
      app.$v.sendPhoneNumber.$touch();
      if (app.$v.sendPhoneNumber.$invalid) {
        return
      } else {
        app.sendVerificationLink (app.sendPhoneNumber, 'phone');
      }
      app.captureScreenShotCanvas('check-phone-number-verification')
    },
    checkSignatureValidation () {
      let app = this
      app.renderSpinnerIcon = true
      let paramsObj = {
        'enrollment_id': app.tempId
      }
      axios.get(process.env.VUE_APP_API_BASE+'/enrollment-link/check-signature', {
        params:  paramsObj
      })
          .then(function (response) {
            if (response.data.status == 'success') {
              let responseData = response.data.data
              app.showVerificationMsg = true
              app.hasSignatureSubmitted = responseData.has_already_submitted
              app.renderSpinnerIcon = false
              app.hasSignatureSubmitted && app.checkForPaymentStatus(responseData.payment_response)
              setTimeout(() => {
                app.captureScreenShotCanvas('signature-validation-success')
              }, 1000)
            }
          })
          .catch(function (error) {
            if (error.response.data.status == 'error') {
              console.log('error', error.response)
              app.renderSpinnerIcon = false
              setTimeout(() => {
              app.captureScreenShotCanvas('signature-validation-failed')
              }, 1000)
            }
          });
    },
    checkForPaymentStatus (paymentStatusInfo) {
      let app = this
      if (!paymentStatusInfo.status) {
        app.showPaymentError = true
        app.paymentErrorMsg =  paymentStatusInfo.message
        app.$emit('paymentInfo', paymentStatusInfo)
      } else {
        app.showPaymentError = false
        app.paymentStatus = paymentStatusInfo.status
      }
      setTimeout(() => {
        app.captureScreenShotCanvas('check-payment-status')
      }, 1000)
    },
    sendVerificationLink (data, type) {
      let app = this
      let paramsObj = {
        'enrollment_id': app.tempId,
        'data': data,
        'type': type
      }
      axios.get(process.env.VUE_APP_API_BASE+'/enrollment-link/send', {
        params:  paramsObj
      })
          .then(function (response) {
            if (response.data.status == 'success') {
              (type === 'email') && (app.showEmailValidTick = true);
              (type === 'phone') && (app.showPhoneValidTick = true);
              app.$notify({
                title: response.data.message,
                icon: 'ti-alert',
                horizontalAlign: 'right',
                verticalAlign: 'top',
                type: 'success'
              })
            }
            setTimeout(() => {
              app.captureScreenShotCanvas(`${type}-verification-success`)
            }, 1000)
          })
          .catch(function (error) {
            if (error.response.data.status == 'error') {
              (type === 'email') && (app.errorEmailMsg = error.response.data.message);
              (type === 'phone') && (app.errorPhoneMsg = error.response.data.message);
              setTimeout(() => {
                app.captureScreenShotCanvas(`${type}-verification-error`)
              }, 1000)
            }
          });
    },
    removeError () {
      let app = this
      app.errorPhoneMsg = ''
      app.errorEmailMsg = ''
    },
    renderModal () {
      let app = this
      app.errorPhoneMsg = '';
      app.errorEmailMsg = '';
      app.sendEmail = app.contactInfo.email_address ? app.contactInfo.email_address : '';
      app.sendPhoneNumber = app.contactInfo.phone_number ? app.contactInfo.phone_number : '';
      app.showEmailValidTick = false;
      app.showPhoneValidTick = false;
      app.showVerificationMsg = false,
      app.hasSignatureSubmitted = false
      app.showPaymentError = false
      app.paymentStatus = false
      app.$v.$reset();
      setTimeout(() => {
        app.captureScreenShotCanvas('render-verification-modal')
      }, 1000)
    },
    submitData () {
      let app = this
      app.$emit('submitEnrollment')
    },
    async captureScreenShotCanvas (captureScreenShotName) {
      const el = this.$refs.extraHealthVerification;
      (async () => {
        this.saveScreenShot((await html2canvas(el)).toDataURL('image/png'), captureScreenShotName);
      })()
    },
    saveScreenShot (image, name) {
      let app = this;
      let data = {
        "enrollment_id" : app.tempId,
        "name" : name,
        "image" : image
      };
      let config = {
        method: 'post',
        url: process.env.VUE_APP_API_BASE+'/save-screenshot',
        headers: { },
        data : data
      };
      axios(config)
          .then(function (response) {
            if (response.data.status == 'success') {
              console.log('screenshot saved');
            }
          })
          .catch(function (error) {
            console.log('screenshot not saved');
            console.log(error);
          });
    },
    routeToCC () {
      let app = this
      app.$emit('extraHealthModalRouteToCC')
    }
  }
}
</script>

<style lang="scss" scoped>
#extra-health-verification {
  .verification-container {
    padding-left: 10px;
  }

  h2 {
    font-size: 22px;
    font-weight: 600;
  }

  .send-verification-button {
    background: #00A550;
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    border-radius: 6px;
    outline: none;
    border: 0px;
    padding: 8px 30px;
    transition: all ease 0.4s;
    margin-right: 15px;
    margin-top: 3px;
  }

  .check-verification-button {
    background: #0094D9;
    color: #fff;
    font-size: 14px;
    font-weight: 700;
    border-radius: 6px;
    outline: none;
    border: 0px;
    padding: 8px 30px;
    transition: all ease 0.4s;
    margin-right: 15px;
    margin-top: 3px;
  }
  .email-phone-alert-success {
    color: #2DB223;
    border-radius: 5px;
    position: relative;
    top: 10px;
  }
  .verification-alert-success {
    background-color: #d4edda;
    color: #155724;
    border: 1px solid #c3e6cb;
    padding: 15px 8px;
    margin: 0;
    border-radius: 5px;
  }
  .verification-alert-danger {
    background-color: #f8d7da;
    color: #721c24;
    border: 1px solid #f5c6cb;
    padding: 15px 8px;
    margin: 0;
    border-radius: 5px;
  }
  .rotational-spinner {
    animation: rotate 0.6s linear infinite;
    width: 20px;
    height: 20px;
    display: inline-block;
  }

  .verify-text {
    padding: 0 10px;
  }

    @keyframes rotate {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }

  .was-validated .form-control:invalid,
  .form-control.is-invalid {
    background-image: none !important;
  }

}
</style>